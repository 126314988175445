/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, ColumnCover, ColumnDiv, Title, Subtitle, Button, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--50 pt--80" name={"introduction"} layout={"l30"}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s5 --center el--1 --full pt--50 flex--center" anim={"7"} animS={"5"} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"} style={{"backgroundColor":"rgba(150,149,101,1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/30673/57810acda9074dd2adcda972bdcec7d7_e=322x0x533x800_s=350x_.jpg"} sizes={"100vw"} style={{"maxWidth":670}} srcSet={"https://cdn.swbpg.com/t/30673/57810acda9074dd2adcda972bdcec7d7_e=322x0x533x800_s=350x_.jpg 350w"} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="js-anim  --anim3 --anim-s5 --center pt--20 flex--top" anim={"3"} animS={"5"}>
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--185 lh--1" content={"Ylana &amp; Sven"}>
                    </Title>

                    <Subtitle className="subtitle-box fs--62 lh--1 mt--0" content={"6 – 5 – 2023"}>
                    </Subtitle>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"text"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--102" content={"De liefde is voor het leven,<br>voorgoed en niet voor even.<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"photogallery"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/8b4798f9ce804391890a6d479193a309_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/30673/8b4798f9ce804391890a6d479193a309_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/8b4798f9ce804391890a6d479193a309_s=660x_.jpg 660w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="mt--50" anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/5204988bb10f49f19d274ff07cbd7e26_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/30673/5204988bb10f49f19d274ff07cbd7e26_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/5204988bb10f49f19d274ff07cbd7e26_s=660x_.jpg 660w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/1284af6d93754fc799851347646d46fa_e=0x205x1536x1843_s=860x_.JPG"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/30673/1284af6d93754fc799851347646d46fa_e=0x205x1536x1843_s=350x_.JPG 350w, https://cdn.swbpg.com/t/30673/1284af6d93754fc799851347646d46fa_e=0x205x1536x1843_s=660x_.JPG 660w, https://cdn.swbpg.com/t/30673/1284af6d93754fc799851347646d46fa_e=0x205x1536x1843_s=860x_.JPG 860w, https://cdn.swbpg.com/t/30673/1284af6d93754fc799851347646d46fa_e=0x205x1536x1843_s=1400x_.JPG 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"info"} style={{"backgroundColor":"#969565"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102" content={"06.05.2023<br>"}>
              </Title>

              <Title className="title-box title-box--center fs--102 mt--10" content={"t' Fornuis aan huis Geraardsbergen, BE"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--50" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/locatie"} content={"Locatie<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"fotka"} style={{"backgroundColor":"#969565"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1150}} columns={"1"}>
            
            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/252de12393ec479394f0361260f980b6_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":611}} srcSet={"https://cdn.swbpg.com/t/30673/252de12393ec479394f0361260f980b6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/252de12393ec479394f0361260f980b6_s=660x_.jpg 660w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--80" name={"information"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"The big day<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"schema"} animS={"2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"14:30"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16" anim={null}>
              
              <Text className="text-box" content={"Aanvang<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"15:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Ceremonie<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"16:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Receptie"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"18:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Dining<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"22:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"Party<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left" anim={"5"} animS={"5"}>
              
              <Title className="title-box" content={"02:00"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--16">
              
              <Text className="text-box" content={"The end<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"photogallery–2"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="mt--80" anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/18667ef0d5f84196894e559295e29c64_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/30673/18667ef0d5f84196894e559295e29c64_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/18667ef0d5f84196894e559295e29c64_s=660x_.jpg 660w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/30673/6208ac943c62411989e250d72323141b_e=192x0x1440x1440_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/30673/6208ac943c62411989e250d72323141b_e=192x0x1440x1440_s=350x_.jpg 350w, https://cdn.swbpg.com/t/30673/6208ac943c62411989e250d72323141b_e=192x0x1440x1440_s=660x_.jpg 660w, https://cdn.swbpg.com/t/30673/6208ac943c62411989e250d72323141b_e=192x0x1440x1440_s=860x_.jpg 860w, https://cdn.swbpg.com/t/30673/6208ac943c62411989e250d72323141b_e=192x0x1440x1440_s=1400x_.jpg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"footer"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/dresscode"} content={"Dress code<br>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/locatie"} content={"Locatie<br>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/rsvp"} content={"RSVP<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"footer-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"We kijken ernaar&nbsp;uit<br>om&nbsp;u te zien!"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}